export const GET_ARTICLES = 'GET_ARTICLES'
export const GET_ARTICLE = 'GET_ARTICLE'
export const GET_FOLDER = 'GET_FOLDER' // тоже самое что GET_ARTICLE, только для папок
export const GET_SERVICE_PAGE = 'GET_SERVICE_PAGE'
export const GET_VACANCIES = 'GET_VACANCIES'
export const GET_VACANCY = 'GET_VACANCY'

export const GET_TREE = 'GET_TREE'
export const GET_BREADCRUMBS = 'GET_BREADCRUMBS'

export const CHANGE_ORDER = 'CHANGE_ORDER'
export const GET_CHILDREN = 'GET_CHILDREN'

export const CREATE_ARTICLE = 'CREATE_ARTICLE'
export const DELETE_ARTICLE = 'DELETE_ARTICLE'
export const DELETE_VACANCY = 'DELETE_VACANCY'
export const CHANGE_ARTICLE = 'CHANGE_ARTICLE'

export const ARTICLE_UPLOAD_FILE = 'ARTICLE_UPLOAD_FILE'
export const ARTICLE_DEL_FILE = 'ARTICLE_DEL_FILE'
export const ARTICLE_DEL_ALL_FILES = 'ARTICLE_DEL_ALL_FILES'

export const VAC_RESPOND = 'VAC_RESPOND'
export const VIEW_ARTICLE = 'VIEW_ARTICLE'

export const SET_ALL_ARTICLES_AS_ARTICLES = 'SET_ALL_ARTICLES_AS_ARTICLES'

export const SET_RIGHTS = 'SET_RIGHTS'