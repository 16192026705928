import axios, { AxiosResponse, AxiosError } from 'axios';
import { SET_USER_DATA, ERROR_LOGIN } from '@/store/user/mutations.type';
import router from '@/router/index';
import store from '@/store';

export const setAuthInterceptor = (Vue, i18n) => {
  axios.interceptors.response.use((response:AxiosResponse) => {
    return response;
  }, (error: AxiosError) => {
    if (error.response == undefined) {
      const cfg: any = error.config
      console.log(`${error.message} at ${cfg.method} ${cfg.url}`);
      return
    }
    const errData: any = error.response.data;
    console.log(error);
    if (error.response.status === 401) {
      localStorage.removeItem('authorization');
      //store.commit(SET_USER, null);
      store.commit(SET_USER_DATA, null); // для теста - чтобы убрать везде SET_USER_
      router.push('/login');
    } else if (error.response.status === 403) {
      console.error('Вход заблокирован')
      store.commit(ERROR_LOGIN, 'Вход заблокирован')
      return Promise.resolve(error)
    } else if (error.response.status === 404) {
      const data: any = error.response.data
      console.error('Что-то не найдено:', data)
    } else if (errData && errData.message) {
      console.error((errData.name ? errData.name + ': ' : '') + errData.message);
    } else if (error instanceof Error) {
      const err = error as AxiosError<{error: string | undefined, no_show: boolean | undefined}>
      const msg = err.response?.data?.error || error.message
      console.error(error.name + ': ' + msg )
      // показывать если не установлен флаг подавления (когда знаем что компонент корректно обработает ошибку )
      if (!err.response?.data?.no_show) Vue.prototype.$message.error(msg) 
      throw msg
    }
    return Promise.reject(error);
  });
};
