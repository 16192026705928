import Vue from 'vue';
import { 
  SET_CLIENTS, 
  SET_CLIENT, 
  ADD_CLIENT, 
  REMOVE_CLIENT, 
  UPDATE_CLIENT, 
  SET_CURRENT_CLIENT, 
  SET_STATISTIC, 
  SET_DIALOG_MESSAGE, 
  SET_FEATURES, 
  SET_FEATURE,
  SET_HANGED_FILES,
  SET_JOURNAL,
  SET_THANKS,
  SET_PROCESSING,
  SET_FILES_STAT,
  SET_BDAYS,
  SET_NOTIF_TEMPLATES,
  UPD_NOTIF_TEMPLATE,
  REMOVE_NOTIF_TEMPLATE,
} from '@/store/client/mutations.type'

import {
  SET_USERS
} from '@/store/user/mutations.type'

import { AxiosResponse } from 'axios';
// import { ActionContext } from 'vuex';

export const getClients = (context: any) => {
  return Vue.axios
    .get(`api/clients`)
    .then((res: any) => {
      return context.commit(SET_CLIENTS, res.data.result)
    })
}

export const getClient = (context: any, client_id) => {
  return Vue.axios
    .get(`api/clients/${client_id}`)
    .then((res:any) => {
      return context.commit(SET_CLIENT, res.data.result)
    })
}

export const createClient = async (context: any, client: any) => {
  if (client.logo) {
    const logo = await Vue.axios.post('api/upload', client.logo)
    client.logo = logo.data.results[0].file.src
  }
  if (client.head) {
    const head = await Vue.axios.post('api/upload', client.head)
    client.head = head.data.results[0].file.src
  }
  const res = await Vue.axios.post(`api/clients`, client)
  return context.commit(ADD_CLIENT, res.data.result)
  // return Vue.axios
  //   .post(`api/clients`, client)
  //   .then((res: any) => {
  //     context.commit(ADD_CLIENT, res.data.result)
  //   })
}

export const clearClient = (context: any) => {
  return context.commit(SET_CLIENT, undefined)
}

export const editClient = async (context: any, client: any) => {
  if (client.logo && client.logo.get('files') instanceof File) {
    const logo = await Vue.axios.post('api/upload', client.logo)
    client.logo = logo.data.results[0].file.src
  }
  if (client.head && client.head.get('files') instanceof File) {
    const head = await Vue.axios.post('api/upload', client.head)
    client.head = head.data.results[0].file.src
  }
  const res = await Vue.axios.put(`api/clients/${client._id}`, client) // проверяем есть ли корзина на минио под клиента
  context.commit(UPDATE_CLIENT, res.data.result) // update in list
  context.commit(SET_CLIENT, res.data.result) // update as opened in dialog  
  return res.data.result
}

// wspace must be a name of client workspace and empty string to exit to client management mode
export const changeWorkspace = async (context: any, wspace: string) => {
  const res: AxiosResponse = await Vue.axios.get(`/api/clients/by_ws/${wspace}`)
  context.commit(SET_CURRENT_CLIENT, res.data)
  context.commit(SET_USERS, [])
  return res.data
}

export const getStatistic = async (context: any, wspace: string) => {
  const res = await Vue.axios.get(`/api/clients/stat/${wspace}`)
  context.commit(SET_STATISTIC, res.data)
}

export const getFilesStat = async (context: any, wspace: string) => {
  const res = await Vue.axios.get(`/api/clients/${wspace}/filestat`)
  context.commit(SET_FILES_STAT, res.data)
}

export const deleteCollection = (context: any, data: { client: string, coll_name: string }) => {
  return Vue.axios
    .delete(`/api/clients/${data.client}/${data.coll_name}`)
    .then(resDelete => {
      context.commit(SET_DIALOG_MESSAGE, resDelete.data.result)
      Vue.axios.get(`/api/clients/stat/${data.client}`)
        .then(resGet => {
          context.commit(SET_STATISTIC, resGet.data)
        })
    })
    .catch(err => {
      throw Error('Ошибка при удалении данных клиента: ' + err)
    })
}

export const getFeatures = (context: any) => {
  return Vue.axios.get('/api/clients/features/all').then(res => {
    context.commit(SET_FEATURES, res.data)
  })
}

export const switchFeature = async (context: any, d: {client_id: string, code: string}) => {
  const {data} = await Vue.axios.put(`/api/clients/features/switch/${d.client_id}/${d.code}`)
  const clientFeatures = {client_id: d.client_id, features: data.features}
  context.commit(SET_FEATURE, clientFeatures)
  return clientFeatures
}

export const sendTextToTelegram = (context: any, d: any) => {
  const data = {
    chat_id: d.channel,
    text: d.text
  }
  return Vue.axios.post(`/api/tg/send`, data)
}

export const cloneClient = (context: any, data: {old: string, workspace: string, name: string}) => {
  return Vue.axios
    .post(`/api/clients/${data.old}/cloneAs/${data.workspace}`, {name: data.name})
    .then((res: any) => {
      console.log('CLONE result is ',res.data.result);
      if (res.data.result) context.commit(ADD_CLIENT, res.data.result)
      return res.data // info - сколько чего создалось, result - новый клиент
    }).catch(err => {
      return err.response.data
    })
}

export const eraseClient = (context: any, client_id: string) => {
  return Vue.axios
    .delete(`/api/clients/${client_id}/fullDelete`)
    .then((res: any) => {
      console.log('Client was totally deleted');
      if (res.data.result) context.commit(REMOVE_CLIENT, client_id)
      return res.data
    })
    .catch((err:any) => {
      console.log('CLIENT ERASE error:',err);
    })
}

export const getHangedFiles = (context: any, client_id: string) => {
  return Vue.axios
    .get('/api/clients/hangedFilesByClient/'+client_id)
    .then(res => {
      return context.commit(SET_HANGED_FILES, res.data)
    }).catch(err => {
      return err.message
    })
}

// удаление произвольного файла
export const delFile = async ({ commit }: any, data: {client_id: string, filename: string}) => {
  return await Vue.axios.post('api/del_file', data)
}

export const delUnusedFiles = (context: any, files: string[]) => {
  return Vue.axios.post('/api/del_minio_files', {files}).then(res => {
    return res.data.result // количество удаленных файлов
  })
}

export const getLogsJournal = (context: any, client_id: string) => {
  return Vue.axios.get('/api/journal/'+client_id).then(res => {
    return context.commit(SET_JOURNAL, res.data) // количество удаленных файлов
  })
}

export const getThanks = async (context: any, client_id: string) => {
  const res = await Vue.axios.get(`/api/clients/${client_id}/all_thanks`)
  context.commit(SET_THANKS, res.data)
  return res.data
}

// Выгрузка одного клиента в SendSay
export const exportOne = async (context: any, client: {client_id: string, name: string }) => {
  context.commit(SET_PROCESSING, true)
  const res = await Vue.axios.post(`/api/clients/export/${client.client_id}`, {name: client.name})
  context.commit(SET_PROCESSING, false)
  return res.data
}

// Выгрузка всех клиентов в SendSay
export const exportAll = async (context: any) => {
  context.commit(SET_PROCESSING, true)
  const res = await Vue.axios.post(`/api/clients/export/all`)
  context.commit(SET_PROCESSING, false)
  return res.data
}

type User = {
  _id: string,
  firstName: string,
  lastName: string,
  birthDate: string,
  googleImage: string | undefined,
}
type BirthdaysRes = {
  today: User[],
  next: {
    format: string,
    users: User[]
  }[]
}

export const getBirthdays = async (context: any, client_id:  string) => {
  const { data }: AxiosResponse<BirthdaysRes> = await Vue.axios.get(`/api/clients/${client_id}/birthdays`)
  context.commit(SET_BDAYS, data)
}

export const getNotifTemplates = async (context: any, client_id:  string) => {
  const { data }: AxiosResponse<{result: any[]}> = await Vue.axios.get(`/api/notif_template/${client_id || '__'}`)
  context.commit(SET_NOTIF_TEMPLATES, data.result)
}

export const saveNotifTemplates = async (context: any, notif_data:  any) => {
  let res: AxiosResponse<{result: any}>
  if (notif_data._id) 
    res = await Vue.axios.put(`/api/notif_template/${notif_data._id}`, notif_data)
  else
    res = await Vue.axios.post(`/api/notif_template`, notif_data)
  context.commit(UPD_NOTIF_TEMPLATE, res.data.result)
  return res.data.result
}

export const deleteNotifTemplate = async (context: any, notif_id: string) => {
  const {data}: AxiosResponse<{result: any}> = await Vue.axios.delete(`/api/notif_template/${notif_id}`)
  context.commit(REMOVE_NOTIF_TEMPLATE, notif_id)
  return data
}

export const runMaintananceTask = async (context: any, data: {client_id: string, task: string}) => {
  const url = `/api/clients/${data.client_id}/maintenance/${data.task}`
  const {data: result}: AxiosResponse<{result: any}> = await Vue.axios.post(url)
  return result
}

// произвольные запросы к SendSay и отсутствие сохранения в store
export const execSendsay = async (context: any, data: {cmd: string, query: string}) => {
  const {data: result}: AxiosResponse<{result: any}> = await Vue.axios.post(`/api/clients/exec_sendsay`, data)
  return result
}